// Fix for IE10 and Windows Mobile
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style');
    msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    );
    document.querySelector('head').appendChild(msViewportStyle);
}

// Animated scrolling
$('a[rel="scroll"]').click(function (event) {
    event.preventDefault();
    var scrolloffset = 0;
    var hash = $(this).attr('href');
    $("html, body").animate({scrollTop: $(hash).offset().top - scrolloffset}, 1200);
});

// Languageswitch
$('.languageswitch').click(function(){
    $(this).toggleClass('open');
});

// Navtoggle
$('.navtoggle').click(function() {
    $('#mobilenav').toggleClass('fixed');
    $('#mobilenav nav').toggle();
    $('.navtoggle .fa').toggleClass('fa-bars fa-times');
    $('body').toggleClass('fixednav');
});

// Sticky Navtoggle
function stickyNav() {
    var scrollTop = $(window).scrollTop();
    var mobileNavBottom = $('#mobilenav').position().top + $('#mobilenav').outerHeight();
    if (scrollTop > mobileNavBottom) {
        $('#mobilenav .navtoggle.fixed').fadeIn('fast');
    } else {
        $('#mobilenav .navtoggle.fixed').fadeOut('fast');
    }
}

$(document).ready(function () {
    navMarker();

    // Tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // Fancybox
    $(".fancybox").fancybox({
        'loop': false,
        helpers: {
            title: {
                type: 'inside'
            }
        }
    });

    $(".fancybox-couverts").fancybox({
        'width': 385,
        'type': 'iframe'
    });

    // FitVids
    if ($.isFunction($.fn.fitVids)) {
        $('.fitvids').fitVids();
    }
});

// Nav marker
function navMarker(){
    var navClass = 'nav ul.navigation',
        marker = $(navClass + ' #marker'),
        current = $(navClass + ' .marked');

    // Position the marker
    marker.css({
        left: current.position().left,
        width: current.outerWidth(),
        display: "block"
    });

    $(navClass + ' li').mouseover(function () {
        var self = $(this),
            offsetLeft = self.position().left,
            width = self.outerWidth() || current.outerWidth(),
            left = offsetLeft == 0 ? 0 : offsetLeft || current.position().left;
        $('.marked').removeClass('marked');
        self.addClass('marked');
        marker.css({
            left: left,
            width: width
        });
    });

    $(navClass).mouseleave(function () {
        $('.marked').removeClass('marked');
        current.addClass('marked');
        marker.css({
            left: current.position().left,
            width: current.outerWidth()
        });
    });
}

// Scroll functions
$(window).scroll(stickyNav);
